import StandardNavigation from "@client/navigation/StandardNavigation";
import Image from "next/image";
import {
  Box,
  Container,
  Typography,
  useTheme,
  makeStyles,
  Button,
} from "@mui/material";
import theme from "@styles/theme";
import FeatureCard from "@client/index/components/FeatureCard";
import LinkToPage from "@client/navigation/components/LinkToPage";
import Link from "next/link";

const Colored = (props: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  return (
    <span style={{ color: theme.palette.primary.main }}>{props.children}</span>
  );
};

export default function Home() {
  const theme = useTheme();
  // const classes = useStyles();
  return (
    <StandardNavigation>
      <Box py={6} style={{ backgroundColor: theme.palette.background.default }}>
        <Container
          maxWidth="lg"
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Box pb={6}>
            <Image src="/images/world_wide_web.svg" width={250} height={250} />
          </Box>
          <Box pb={6}>
            <Typography
              variant="h2"
              style={{ fontWeight: 600, color: theme.palette.text.primary }}
            >
              Build <Colored>tax application </Colored> of any kind{" "}
              <Colored>without having to worry</Colored> about maintaining the
              tax legislation <Colored>yourself</Colored>.
            </Typography>
          </Box>
          <Box
            sx={{
              gap: theme.spacing(2),
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            <FeatureCard
              icon={"🚀"}
              title={"Focus on your app"}
              description={
                "Don't be distracted by complicated tax legislation. We handle the complicated logic so that you can focus on your app."
              }
            />
            <FeatureCard
              icon={"👨‍🎓"}
              title={"Created by Experts"}
              description={
                "The API is maintained by tax experts that convert the most interesting use cases into api routes."
              }
            />
            <FeatureCard
              icon={"✅"}
              title={"Up to Date"}
              description={
                "We make sure that the current tax figures are up to date."
              }
            />
          </Box>
        </Container>
      </Box>

      <Box
        my={5}
        style={{
          backgroundColor: theme.palette.secondary.main,
          minHeight: "400px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Box py={10}>
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                fontWeight: 600,
                color: "white",
                marginBottom: theme.spacing(6),
              }}
            >
              Want to get started? Jump into the documentation!
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link href="/routes/self-employed-basics" passHref>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{ maxWidth: "250px" }}
                >
                  Documentation
                </Button>
              </Link>

              {/* <div style={{ width: theme.spacing(2) }}></div>
              <Link href="/api-playground" passHref>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{ maxWidth: "250px" }}
                >
                  API Playground
                </Button>
              </Link> */}
            </div>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box
          py={10}
          sx={{
            gap: theme.spacing(6),
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src="/images/expert_engine.png"
              width={1345 * 0.25}
              height={803 * 0.25}
              layout="intrinsic"
            />
          </div>
          <div style={{ justifyContent: "center" }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{
                fontWeight: 500,
                color: theme.palette.text.primary,
                fontSize: 20,
              }}
            >
              The Aeco Tax API is part of the <Colored>expert engine,</Colored> a
              collection of technologies{" "}
              <Colored>
                that help legal and tax companies accelerate their digital
                offering.
              </Colored>
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: 500,
                color: theme.palette.text.primary,
                fontSize: 20,
              }}
            ></Typography>
            <Box py={3}>
              {" "}
              <LinkToPage url="https://www.aeco.cloud">
                {" "}
                <Button color="primary" variant="contained">
                  Visit the Aeco website
                </Button>
              </LinkToPage>
            </Box>
          </div>
        </Box>
      </Container>
    </StandardNavigation>
  );
}
